import config from '../config/keys';

export const url = config.apiUrl;

const _getUri = endpointUri => `${url}/${endpointUri}`;

const _getHeaders = async () => {
	const userToken = localStorage.getItem('token');
	return {
		'Content-Type': 'application/json',
		'Authorization': `Bearer: ${userToken}`,
	};
};

const _handleResponse = async (response) => {
	const { status, statusText } = response;
	if (status !== 200) {
		throw new Error(statusText);
	}
	const contentType = response.headers.get('content-type');
	const responseType =
		contentType && contentType.indexOf('application/json') !== -1 ?
			'json' : 'text';
	return await response[responseType]();
};

const _request = async (endpointUri, config) => {
	let route = (typeof endpointUri === 'string' && endpointUri.length > 0) && endpointUri;
	if (!route) {
		throw new Error('no route provided');
	}
	const uri = _getUri(endpointUri);
	try {
		config.headers = {
			...await _getHeaders(),
			...config.headers
		};
		const response = await fetch(uri, config);
		return await _handleResponse(response);
	} catch (err) {
		throw new Error(`error when calling '${uri}' - message: "${err}"`);
	}
};

export const get = (url, config = {}) =>
	_request(url, {
		...config,
		mode: 'cors',
		method: 'GET'
	});

export const post = async (url, data, config = {}) =>
	_request(url, {
		...config,
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'POST',
		body: JSON.stringify(data),
		mode: 'cors'
	});

export const put = async (url, data, config = {}) =>
	_request(url, {
		...config,
		headers: {
			'Content-Type': 'application/json'
		},
		method: 'PUT',
		mode: 'cors',
		body: JSON.stringify(data)
	});

export const remove = async (url, config = {}) =>
	_request(url, {
		...config,
		mode: 'cors',
		method: 'DELETE',
	});
