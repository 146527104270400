import constants from '../constants'

const initialState = {
	open: false
};

const { OPEN_MODAL, CLOSE_MODAL } = constants

const modalReducer = (state = initialState, { type }) => {
	switch (type) {
		case OPEN_MODAL:
			return { open: true };
		case CLOSE_MODAL:
			return { ...initialState };
		default:
			return state;
	}
}

export default modalReducer