import * as Api from './api'
import Endpoints from '../enums/endpoints'

const activity = {
	get: async () => {
    try {
      const url = Endpoints.GET_ACTIVITY_FEED
      const request = await Api.get(url)
      return request.response
    } catch(err) {
      return ({ error: err })
    }
  },
}

export default activity