export const inputFields = [
  {id: "first_name", label:"Primeiro Nome", type:"text", name:"first_name", required: true, errorMessage: "Primeiro nome é obrigatório.", showOnMode: ["create", "edit"]},
  {id: "last_name", label:"Ultimo Nome", type:"text", name:"last_name", required: true, errorMessage: "Ultimo nome é obrigatório.", showOnMode: ["create", "edit"]},
  {id: "email", label:"Email", type:"email", name:"email", required: true, errorMessage: "Email é obrigatório.", showOnMode: ["create", "edit"]},
  {id: "password", label:"Senha", type:"password", name:"password", required: true, errorMessage: "Senha é obrigatório.", showOnMode: ["create", "edit"]},
]

export const formInitialState = formData => {
  return ({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    ...formData,
  })
}
