import constants from '../constants';

const initialState = {
	report: [],
	reportLoading: false
};

const { REPORT_SUCCESS, REPORT_FAILURE, REPORT_LOADING } = constants

const reportReducer = (state = initialState, { payload, type }) => {
	switch (type) {
		case REPORT_LOADING:
			return { ...state, reportLoading: true }
		case REPORT_SUCCESS:
			return { report: payload, rerportLoading: false }
		case REPORT_FAILURE:
			return { ...initialState }
		default:
			return state;
	}
}

export default reportReducer