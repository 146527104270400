import constants from '../constants';

const initialState = {
  user: null,
  users: [],
};

const {
  SET_USER,
  REMOVE_USER,
  SUCCESS_USER,
  FAILURE_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
} = constants;

const accountReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_USER:
      return { ...state, user: payload };
    case REMOVE_USER:
      return { ...initialState };
    case SUCCESS_USER:
      return { ...state, users: payload };
    case FAILURE_USER:
      return { ...initialState };
    case FETCH_USER_SUCCESS:
      return { ...state, user: payload };
    case FETCH_USER_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export default accountReducer;
