import * as Api from './api'
import Endpoints from '../enums/endpoints'

const authenticate = {
	isAuthenticated: !!localStorage.getItem('token'),

	login: async (email, password) => {
		const { LOGIN } = Endpoints
		const request = await Api.post(LOGIN, {
			email: email,
			password: password,
		})
		if (request.jwt) {
			authenticate.isAuthenticated = true
			return { token: request.jwt, user: request.email, data: request.data}
		}
		return { message: 'user not found' }
	},

	logout: () => {
		authenticate.isAuthenticated = false
		localStorage.removeItem('token')
		localStorage.removeItem('user')
		localStorage.removeItem('roles')
	},
};

export default authenticate;