import styled from 'styled-components'

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  padding: 15px;
`
