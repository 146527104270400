import types from '../constants'

const showMessage = () => ({
	type: types.SHOW_MESSAGE,
})

const hideMessage = () => ({
	type: types.HIDE_MESSAGE,
})

const messageActions = {
	showMessage,
	hideMessage, 
}

export default messageActions
