import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { themeDefault } from "./themes";
import { App, Login } from "./components/organisms";
import { PrivateRoute } from "./components/molecules";
import configureStore from "./store/config/configureStore";
import ErrorBoundary from "./components/atoms/ErrorBoundary";

const GlobalStyle = createGlobalStyle`
  * {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const store = configureStore();

ReactDOM.render(
  <StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Aliança de Misericórdia</title>
    </Helmet>
    <GlobalStyle />
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={themeDefault}>
          <BrowserRouter>
            <Switch>
              <Route exact path={"/"} component={Login} />
              <PrivateRoute path={"/dashboard"} component={App} />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
);
