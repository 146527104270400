import styled from 'styled-components'

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 500px;
`

export const Column = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`

export const PaperWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Text = styled.h1`
  font-size: 16px;
  text-align: center;
  font-weight: 900;
`

export const Img = styled.img`
  width: 100px;
  height: 100px;
`

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`