import types from '../constants'

const showLoader = data => ({
	type: types.SHOW_LOADER,
	payload: data
})

const hideLoader = () => ({
	type: types.HIDE_LOADER,
})

const loaderActions = {
	showLoader,
	hideLoader, 
}

export default loaderActions
