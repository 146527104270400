import constants from '../constants';

const initialState = {
	signaturesByPerson: {}
};

const { SIGNATURE_SUCCESS, SIGNATURE_FAILURE, ADD_SIGNATURE, UPDATE_SIGNATURE, CLEAN_SIGNATURE } = constants

const serializeSignaturesByPerson = payload => {
  const result = payload.reduce((acc, current) => {
    if (Object.values(acc).length === 0 || !acc[current.personId]) {
      return ({
        ...acc,
        [current.personId]: [
          { ...current }
        ]
      })
    }
    return ({
      ...acc,
      [current.personId]: [
        ...acc[current.personId], 
        { ...current }
      ]
    })
  }, {})
  
  return result
}

const signatureReducer = (state = initialState, { payload, type }) => {
	switch (type) {
		case SIGNATURE_SUCCESS:
			return { signaturesByPerson: serializeSignaturesByPerson(payload) }
		case SIGNATURE_FAILURE:
      return initialState
    case ADD_SIGNATURE:
      const existsLSignatureByPersonList = state.signaturesByPerson[payload.personId]
      return {
        ...state,
        signaturesByPerson: {
          [payload.personId]: [ 
            ...(existsLSignatureByPersonList ? state.signaturesByPerson[payload.personId] : []),
            { id: payload.id,
              date: payload.date,
              checkpoint: payload.checkpoint,
              personId: payload.personId, 
              reason: payload.reason
          }]
        }
      }
    case UPDATE_SIGNATURE:
      return {
        ...state,
        signaturesByPerson: {
          [payload.personId]: state.signaturesByPerson[payload.personId].reduce((acc, current) => {
          if (current.id === payload.id) {
            current.checkpoint = payload.checkpoint
            current.reason = payload.reason
          }
          return [...acc, current]
        }, [])}
      }
    case CLEAN_SIGNATURE:
      return {
        signaturesByPerson: initialState // this clean all the signature temporarily add but not saved. 
      }
		default:
			return state
	}
}

export default signatureReducer