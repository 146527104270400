import constants from '../constants';

const initialState = {
	activities: []
};

const { ACTIVITY_FEED_SUCCESS, ACTIVITY_FEED_FAIL } = constants

const activityReducer = (state = initialState, { payload, type }) => {
	switch (type) {
		case ACTIVITY_FEED_SUCCESS:
			return { ...state, activities: payload }
		case ACTIVITY_FEED_FAIL:
			return { ...initialState }
		default:
			return state;
	}
}

export default activityReducer