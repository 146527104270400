import styled from 'styled-components'

export const Anchor = styled.a.attrs(props => ({
  href: props.url,
  target:"_blank",
}))`
  color: ${({ theme }) => theme.colors.primary};
`

export const Img = styled.img`
  width: auto;
  height: 25px;
  padding-top: 10px;
`

export const CopyrightWrrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
`