import * as Api from './api'
import Endpoints from '../enums/endpoints'

const people = {
	get: async () => {
    try {
      const url = Endpoints.GET_PEOPLE
      const request = await Api.get(url)
      return request.response
    } catch(err) {
      return ({ error: err })
    }
  },
  post: async payload => {
    try {
      const url = Endpoints.POST_PERSON
      const { item, data } = await Api.post(url, payload)
      return [item, data]
    } catch(err) {
      return ({ error: err })
    }
  },
  update: async payload => {
    try {
      const url = Endpoints.UPDATE_PERSON
      const { data } = await Api.put(url, payload)
      return data
    } catch(err) {
      return ({ error: err })
    }
  },
  delete: async id => {
    try {
      const url = Endpoints.DELETE_PERSON(id)
      const { data } = await Api.remove(url)
      return data
    } catch(err) {
      return ({ error: err })
    }
  }
}

export default people