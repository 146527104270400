import validate from 'uuid-validate'
import types from '../constants'
import actions from '.'
import people from '../../services/people'
import signature from '../../services/signature'

const successPeople = data => ({
  type: types.SUCCESS_PEOPLE,
  payload: data,
})

const failurePeople = () => ({
  type: types.FAILURE_PEOPLE,
})

const loadPeople = () => async dispatch => {
  dispatch(actions.loader.showLoader)
  try {
    const dataLoad = await people.get()
    dispatch(successPeople(dataLoad || []))
  } catch(err) {
    dispatch(failurePeople())
    dispatch(actions.loader.hideLoader)
  }
}

const createPerson = (payload, signatures = undefined) => async dispatch => {
  dispatch(actions.loader.showLoader)
  try {
    const [item, data] = await people.post(payload)
    
    if(Object.keys(signatures).length > 0) {
      const signatureWithPersonId = signatures[0].map(sign => ({ 
        ...sign,
        personId: item 
      }))
      await signature.post(signatureWithPersonId)
    }

    dispatch(successPeople(data || []))
    return { success: true}
  } catch(err) {
    dispatch(failurePeople())
    dispatch(actions.loader.hideLoader)
    return { success: false, error: err}
  }
}

const updatePerson = (data, signatures = undefined) => async dispatch => {
  dispatch(actions.loader.showLoader)
  try {
    const peopleResult = await people.update(data)
    if(Object.keys(signatures).length > 0) {
      const updatedSignature = []
      const newSignature = []

      signatures[data.id].forEach(sign => {
        //when uuid it means item is new and not persisted yet
        validate(sign.id) ? newSignature.push({ 
          ...sign, personId: data.id
         }) : updatedSignature.push({ 
          ...sign })
      })
      if(newSignature.length > 0) await signature.post(newSignature)
      if(updatedSignature.length > 0) await signature.update(updatedSignature)
    }
    dispatch(successPeople(peopleResult || []))
    return { success: true}
  } catch(err) {
    dispatch(failurePeople())
    dispatch(actions.loader.hideLoader)
    return { success: false, error: err}
  } finally {
    dispatch(actions.loader.hideLoader)
  }
}

const deletePerson = (id) => async dispatch => {
  dispatch(actions.loader.showLoader)
  try {
    const data = await people.delete(id)
    dispatch(successPeople(data || []))
    return { success: true}
  } catch(err) {
    dispatch(failurePeople())
    dispatch(actions.loader.hideLoader)
    return { success: false, error: err}
  } finally {
    dispatch(actions.loader.hideLoader)
  }
}

const peopleActions = {
  loadPeople,
  createPerson,
  updatePerson,
  deletePerson,
}

export default peopleActions
