import { combineReducers } from 'redux'
import account from './account'
import loader from './loader'
import people from './people'
import modal from './modal'
import signature from './signature'
import message from './message'
import report from './report'
import activity from './activity'

const rootReducer = combineReducers({
	account,
	loader,
	people,
	modal,
	signature,
	message,
	report,
	activity,
})

export default rootReducer
