import React from 'react'
import { Button } from '@material-ui/core'
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone'
import SaveTwoTone from '@material-ui/icons/SaveTwoTone'
import { Input, Selector } from '../../../../atoms'
import { Message } from '../../../../molecules'
import { Form, FormFooter, FormSpacer } from './styled'
import { inputFields } from '../../helpers/InputFields'
import { SignatureList } from '../'
import Constants from './constants'


const ModalContent = ({ 
  handleChange,
  state,
  signatures,
  message,
  handleModalClose,
  handleSubmit,
  mode,
  hideMessage,
 }) => (
    <Form>
      {inputFields.map(field => (
        <Input
          key={field.id}
          id={field.name}
          label={field.label}
          type={field.type}
          name={field.name}
          value={state[field.name]}
          required={field.required}
          validationMessage={field.errorMessage}
          error={false/** TO DO: CHOOSE/IMPLEMENT ERROR MANAGEMENT FOR FORM */}
          onChange={handleChange} />
      ))}
      <Selector 
        id="status"
        label="Situação do Processo"
        name="status"
        selectedOption={state.status}
        items={Constants.status}
        onChange={handleChange} />
        <FormSpacer>
          <Selector 
            id="processtype"
            label="Tipo de Processo"
            name="processtype"
            selectedOption={state.processtype}
            items={Constants.processTypes}
            onChange={handleChange} />
          <Selector 
            id="processfrequency"
            label="Frequencia"
            name="processfrequency"
            selectedOption={state.processfrequency}
            items={Constants.processFrequencies}
            onChange={handleChange} />
          {state.processtype === 'Carta Precatória' && 
            <Input
              id="additionalInformation"
              label="Informações Adicionais"
              type="text"
              name="additionalInformation"
              value={state.additionalInformation}
              onChange={handleChange} />
          }
        </FormSpacer>
      <SignatureList
        personId={state.id || 0} 
        signaturesList={signatures[state.id || 0]}
      />
      <FormFooter>
        {message && 
          <Message
            open={message}
            severity="error" 
            onClose={hideMessage} 
            message={'Necessário Prencher todos os campos.'}
          />
        }
        <Button
          key="button-submit"
          type={'submit'}
          variant={'contained'}
          onClick={ev => handleSubmit(ev, mode)}
        >
          <SaveTwoTone />
          {mode === 'create' ? 'Confirmar Criação' : 'Confirmar Edição'}
        </Button>
        <Button
          key="button-cancel"
          type={'button'}
          variant={'contained'}
          onClick={handleModalClose}
        >
          <CancelTwoToneIcon />
          Cancelar
        </Button>
      </FormFooter>
    </Form>
  )
 
export default ModalContent