import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import dashboardRedirect from "../../../utils/dashboardRedirect";
import { useHistory, useLocation } from "react-router";
import sanitizeString from "../../../utils/sanitizeString";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const menuRoutes = [
  {
    name: "Pagina Inicial",
    icon: <InboxIcon />,
    roles: ["admin", "user"],
    path: "home",
  },
  { name: "Colaborador", icon: <InboxIcon />, roles: ["admin"], path: "user" },
  { name: "Registros de Atividades", icon: <InboxIcon />, roles: ["admin"], path: "activity" },
  {
    name: "Assinaturas",
    icon: <InboxIcon />,
    roles: ["admin", "user"],
    path: "person",
  },
];

export const Sidebar = ({ open, toggleDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const roles = sanitizeString(localStorage.getItem("roles"));

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuRoutes.map(
          (aRoute, index) =>
            aRoute.roles.includes(roles) && (
              <>
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    dashboardRedirect(aRoute.path, history, location);
                  }}
                >
                  <ListItemIcon>{aRoute.icon}</ListItemIcon>
                  <ListItemText primary={aRoute.name} />
                </ListItem>
                <Divider />
              </>
            )
        )}
      </List>
    </div>
  );

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      {list()}
    </SwipeableDrawer>
  );
};
