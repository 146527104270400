import types from '../constants'
import actions from '.'
import activity from '../../services/activity'

const successActivity = data => ({
  type: types.ACTIVITY_FEED_SUCCESS,
  payload: data,
})

const failureActivity = () => ({
  type: types.ACTIVITY_FEED_FAIL,
})

const fetchActivity = () => async dispatch => {
  dispatch(actions.loader.showLoader)
  try {
    const dataLoad = await activity.get()
    dispatch(successActivity(dataLoad || []))
  } catch(err) {
    dispatch(failureActivity())
    dispatch(actions.loader.hideLoader)
  }
}



const activityActions = {
  fetchActivity,
}

export default activityActions
