import * as Api from './api'
import Endpoints from '../enums/endpoints'

const report = {
	getReportNoSignature: async (data) => {
    try {
      const url = Endpoints.GET_REPORT_NO_SIGNATURE
      const request = await Api.post(url, data)
      return request.response || []
    } catch(err) {
      return ({ error: err })
    }
  },
	getReportNoShow: async (data) => {
    try {
      const url = Endpoints.GET_REPORT_NO_INFO_SIGNATURE
      const request = await Api.post(url, data)
      return request.response || []
    } catch(err) {
      return ({ error: err })
    }
  },
	getReporByProcess: async (data) => {
    try {
      const url = Endpoints.GET_REPORT_BY_PROCESS
      const request = await Api.post(url, data)
      return request.response || []
    } catch(err) {
      return ({ error: err })
    }
  }
}

export default report