import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Content, Column, PaperWrapper, Text, Img, ImgWrapper } from './styled';
import { makeStyles } from '@material-ui/core/styles';
import Colaborador from '../../../assets/images/colaborador.png';
import LivramentoCondicional from '../../../assets/images/livramentoCondicional.png';
import ActivityFeed from '../../../assets/images/activityFeed.png';
import dashboardRedirect from '../../../utils/dashboardRedirect';
import sanitizeString from '../../../utils/sanitizeString';

import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '200px',
    height: '180px',
    cursor: 'pointer',
  },
}));
export const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const roles = sanitizeString(localStorage.getItem('roles'));

  return (
    <Content>
      {roles === 'admin' && (
        <Column onClick={() => dashboardRedirect('user', history, location)}>
          <Paper elevation={3} className={classes.paper}>
            <PaperWrapper>
              <ImgWrapper>
                <Img src={Colaborador} alt='Colaborador - Sistema' />
              </ImgWrapper>
              <Text>Colaboradores</Text>
            </PaperWrapper>
          </Paper>
        </Column>
      )}
      <Column onClick={() => dashboardRedirect('person', history, location)}>
        <Paper elevation={3} className={classes.paper}>
          <PaperWrapper>
            <ImgWrapper>
              <Img
                src={LivramentoCondicional}
                alt='Assinaturas - Sistema'
              />
            </ImgWrapper>
            <Text>Assinaturas</Text>
          </PaperWrapper>
        </Paper>
      </Column>
      <Column onClick={() => dashboardRedirect('activity', history, location)}>
        <Paper elevation={3} className={classes.paper}>
          <PaperWrapper>
            <ImgWrapper>
              <Img
                src={ActivityFeed}
                alt='Atividades - Sistema'
              />
            </ImgWrapper>
            <Text>Atividades</Text>
          </PaperWrapper>
        </Paper>
      </Column>
    </Content>
  );
};
