import constants from '../constants'

const initialState = {
	show: false
};

const { SHOW_LOADER, HIDE_LOADER } = constants

const loaderReducer = (state = initialState, { payload, type }) => {
	switch (type) {
		case SHOW_LOADER:
			return { ...state, show: payload };
		case HIDE_LOADER:
			return { ...initialState };
		default:
			return state;
	}
}

export default loaderReducer