/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone';
import { Button } from '@material-ui/core';
import { FilterDataTable, Modal } from '../../molecules';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../store/actions';
import { Content, Row } from './styled';
import { DataTableColumns, formInitialState } from './helpers';
import { ModalContent } from './components';

export const Users = () => {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [mode, setMode] = useState('');
  const [state, setState] = useState(formInitialState());

  const { users, modalStatus, message } = useSelector((state) => ({
    users: state.account.users,
    modalStatus: state.modal.open,
    message: state.message.open,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(actions.account.loadUsers());
    } catch (error) {
      throw new Error(error);
    }
  }, []);

  const filteredItems =
    users?.filter((item) =>
      item.first_name?.toUpperCase().includes(filterText.toUpperCase())
    ) || [];

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeHolder='Primeiro Nome'
        arialLabel='Nome para buscar'
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleModalOpen = (formData) => {
    setMode(formData ? 'edit' : 'create');
    setState(formInitialState(formData));
    dispatch(actions.modal.openModal());
  };

  const handleModalClose = () => {
    dispatch(actions.modal.closeModal());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event, mode) => {
    event.preventDefault();
    // TO DO : improve this logic to better reading. Get required from the input configuration and find out what is required or not
    if (
      state.first_name === '' ||
      state.last_name === '' ||
      state.email === '' ||
      state.password === ''
    ) {
      dispatch(actions.message.showMessage());
      return;
    }
    if (mode === 'create') {
      dispatch(actions.account.createUser(state));
    } else if (mode === 'edit') {
      dispatch(actions.account.updateUser(state));
    }
    dispatch(actions.modal.closeModal());
  };


  return (
    <Content>
      <Modal
        open={modalStatus}
        onClose={handleModalClose}
        title={`${mode === 'create' ? 'Criar' : 'Editar'} novo Colaborador`}
        body={
          <ModalContent
            handleChange={handleChange}
            state={state}
            message={message}
            handleModalClose={handleModalClose}
            handleSubmit={handleSubmit}
            mode={mode}
            hideMessage={() => dispatch(actions.message.hideMessage())}
          />
        }
      />
      <Row>
        <Button
          key='button-submit'
          type={'submit'}
          variant={'contained'}
          onClick={() => handleModalOpen(null)}
        >
          <PersonAddTwoToneIcon />
          Criar Novo Colaborador
        </Button>
      </Row>
      <DataTable
        columns={DataTableColumns}
        data={filteredItems}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Linhas por página:',
          rangeSeparatorText: 'de',
        }}
        highlightOnHover
        persistTableHead
        noDataComponent={'Nenhuma informação disponível'}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={resetPaginationToggle}
        onRowClicked={(row) => handleModalOpen(row)}
      />
    </Content>
  );
};
