import types from '../constants';
import actions from '.';
import report from '../../services/report';

const reportSuccess = (data) => ({
  type: types.REPORT_SUCCESS,
  payload: data,
});

const reportFailure = () => ({
  type: types.REPORT_FAILURE,
});

const reportLoading = () => ({
  type: types.REPORT_LOADING,
});

const loadReportNoSignature =
  ({ startDate, endDate, processType }) =>
  async (dispatch) => {
    dispatch(actions.loader.showLoader);
    dispatch(reportLoading());
    try {
      const dataLoad = await report.getReportNoSignature({
        startDate,
        endDate,
        processType,
      });

      if (dataLoad.error) {
        dispatch(reportSuccess([]));
        return;
      }

      dispatch(reportSuccess(dataLoad));
    } catch (err) {
      dispatch(reportFailure());
      dispatch(actions.loader.hideLoader);
    } finally {
      dispatch(actions.loader.hideLoader);
    }
  };

const loadReportNoShow =
  ({ startDate, endDate, processType }) =>
  async (dispatch) => {
    dispatch(actions.loader.showLoader);
    dispatch(reportLoading());

    try {
      const dataLoad = await report.getReportNoShow({
        startDate,
        endDate,
        processType,
      });

      if (dataLoad.error) {
        dispatch(reportSuccess([]));
        return;
      }

      dispatch(reportSuccess(dataLoad));
    } catch (err) {
      dispatch(reportFailure());
      dispatch(actions.loader.hideLoader);
    } finally {
      dispatch(actions.loader.hideLoader);
    }
  };

const loadReportByProcess =
  ({ processType }) =>
  async (dispatch) => {
    dispatch(actions.loader.showLoader);
    dispatch(reportLoading());

    try {
      const dataLoad = await report.getReporByProcess({
        processType,
      });

      if (dataLoad.error) {
        dispatch(reportSuccess([]));
        return;
      }

      dispatch(reportSuccess(dataLoad));
    } catch (err) {
      dispatch(reportFailure());
      dispatch(actions.loader.hideLoader);
    } finally {
      dispatch(actions.loader.hideLoader);
    }
  };

const reportActions = {
  reportSuccess,
  reportFailure,
  loadReportNoSignature,
  loadReportNoShow,
  loadReportByProcess,
};

export default reportActions;
