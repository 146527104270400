import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button, IconButton } from '@material-ui/core';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Input } from '../../../../atoms';
import { ListHeader, ButtonWrapper } from './styled';
import actions from '../../../../../store/actions';

const SignatureList = ({ personId, signaturesList }) => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.signature.loadSignatureByPerson(personId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggle = (ev, value) => {
    dispatch(
      actions.signature.updateSignature({
        id: value.id,
        date: value.date,
        checkpoint: ev.currentTarget.value,
        personId: personId,
        reason: value.reason,
      })
    );
  };

  const handleReason = (input, value) => {
    dispatch(
      actions.signature.updateSignature({
        id: value.id,
        date: value.date,
        checkpoint: value.checkpoint,
        personId: personId,
        reason: input,
      })
    );
  };

  const handleDatePicker = (event) => {
    const formatedPickedDate = moment(event.target.value).format('YYYY-MM-DD');
    setSelectedDate(formatedPickedDate);
  };

  const addSignatureDate = () => {
    if (signaturesList?.find((item) => item.date === selectedDate)) return;
    dispatch(
      actions.signature.addSignature({
        id: uuidv4(),
        date: selectedDate,
        checkpoint: '0',
        personId: personId,
        reason: '',
      })
    );
  };

  return (
    <>
      <ListHeader>
        <Input
          key='signature-date'
          id='signature-date'
          label='Data Assinatura'
          type='date'
          name='signature-date'
          value={selectedDate}
          onChange={handleDatePicker}
          format='dd-MM-yyyy'
        />
        <ButtonWrapper>
          <Button
            key='button-add-signature'
            type={'button'}
            variant={'contained'}
            onClick={addSignatureDate}
          >
            Adicionar Data
          </Button>
        </ButtonWrapper>
      </ListHeader>
      <Table size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell>Data da Assinatura</TableCell>
            <TableCell>Assinou?</TableCell>
            <TableCell>Motivo</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {signaturesList
            ?.sort(
              ({ date: previousDate }, { date: currentDate }) =>
                new Date(previousDate) - new Date(currentDate)
            )
            .map((signature, index) => (
              <TableRow
                key={`checkbox-list-label-${index}`}
                rowTextColor={() => {
                  switch (signature.checkpoint) {
                    case '1':
                      return 'blue';
                    case '2':
                      return 'red';
                    default:
                      return 'black';
                  }
                }}
              >
                <TableCell>
                  {moment(signature.date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <RadioGroup
                    row
                    value={signature.checkpoint}
                    onChange={(ev) => handleToggle(ev, signature)}
                    aria-label='assinou'
                    name='customized-radios'
                  >
                    <FormControlLabel
                      labelPlacement='start'
                      value='1'
                      control={<Radio color='primary' />}
                      label='Sim'
                    />
                    <FormControlLabel
                      labelPlacement='start'
                      value='2'
                      control={<Radio color='secondary' />}
                      label='Não'
                    />
                    <FormControlLabel
                      value='0'
                      labelPlacement='start'
                      disabled
                      control={<Radio />}
                      label='N/A'
                    />
                  </RadioGroup>
                </TableCell>
                  <TableCell>
                    <Input
                      key='signature-reason'
                      id='signature-reason'
                      type='input'
                      name='signature-reason'
                      value={signature.reason}
                      onChange={(ev) =>
                        handleReason(ev.target.value, signature)
                      }
                      multiline
                    />
                  </TableCell>
                <TableCell>
                  <IconButton
                    aria-label='delete'
                    onClick={() =>
                      dispatch(actions.signature.deleteSignature(signature))
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )) || []}
        </TableBody>
      </Table>
    </>
  );
};

export default SignatureList;
