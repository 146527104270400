import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  background-color: rgb(169 169 169 / 10%);
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  margin: 10px;
  width: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  background-color: white;
  overflow: auto;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Img = styled.img`
  width: auto;
  height: 140px;
  padding-top: 10px;
`;
