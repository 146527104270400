import constants from '../constants'

const initialState = {
	open: false
};

const { SHOW_MESSAGE, HIDE_MESSAGE } = constants

const messageReducer = (state = initialState, { type }) => {
	switch (type) {
		case SHOW_MESSAGE:
			return { ...state, open: true };
		case HIDE_MESSAGE:
			return { ...initialState };
		default:
			return state;
	}
}

export default messageReducer