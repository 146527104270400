import React from "react";
import { Button } from "@material-ui/core";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SaveTwoTone from "@material-ui/icons/SaveTwoTone";
import { Input, Selector } from '../../../../atoms'
import { Message } from "../../../../molecules";
import { Form, FormFooter } from "./styled";
import { inputFields } from "../../helpers/InputFields";

const ModalContent = ({
  handleChange,
  state,
  message,
  handleModalClose,
  handleSubmit,
  mode,
  hideMessage,
}) => (
  <Form>
    {inputFields.map((field) => (
      <Input
        key={field.id}
        id={field.name}
        label={field.label}
        type={field.type}
        name={field.name}
        value={state[field.name]}
        required={field.required}
        validationMessage={field.errorMessage}
        error={false /** TO DO: CHOOSE/IMPLEMENT ERROR MANAGEMENT FOR FORM */}
        onChange={handleChange}
        disabled={!field.showOnMode.includes(mode)}
      />
    ))}
    <Selector
      id="roles"
      label="Roles"
      name="roles"
      selectedOption={state.roles || ''}
      items={['user', 'admin']}
      onChange={handleChange}
    />
    <FormFooter>
      {message && (
        <Message
          open={message}
          severity="error"
          onClose={hideMessage}
          message={"Necessário Prencher todos os campos."}
        />
      )}
      <Button
        key="button-submit"
        type={"submit"}
        variant={"contained"}
        onClick={(ev) => handleSubmit(ev, mode)}
      >
        <SaveTwoTone />
        {mode === "create" ? "Confirmar Criação" : "Confirmar Edição"}
      </Button>
      <Button
        key="button-cancel"
        type={"button"}
        variant={"contained"}
        onClick={handleModalClose}
      >
        <CancelTwoToneIcon />
        Cancelar
      </Button>
    </FormFooter>
  </Form>
);

export default ModalContent;
