import Person from '../components/organisms/Person';
import User from '../components/organisms/Users';
import Home from '../components/organisms/Home';
import ActivityFeed from '../components/organisms/ActivityFeed';

const DashboardRoutes = [
	{
		path: '/home',
		name: 'Home',
		component: Home,
		icon: '',
		layout: '/dashboard',
	},
	{
		path: '/person',
		name: 'Person',
		component: Person,
		layout: '/dashboard',
		icon: '',
	},
	{
		path: '/user',
		name: 'Users',
		component: User,
		layout: '/dashboard',
		icon: '',
	},
	{
		path: '/activity',
		name: 'Activity Feed',
		component: ActivityFeed,
		layout: '/dashboard',
		icon: '',
	},
];

export default DashboardRoutes;
