import validate from 'uuid-validate'
import types from '../constants'
import actions from '.'
import signature from '../../services/signature'

const signatureSuccess = data => ({
	type: types.SIGNATURE_SUCCESS,
	payload: data
})

const signatureFailure = () => ({
	type: types.SIGNATURE_FAILURE,
})

const loadSignatureByPerson = personId => async dispatch => {
	dispatch(actions.loader.showLoader)
  try {
    const dataLoad = await signature.get(personId)
    
    if(dataLoad.error) {
      dispatch(signatureSuccess([]))
      return
    }
    
    dispatch(signatureSuccess(dataLoad))
  } catch(err) {
    dispatch(signatureFailure())
    dispatch(actions.loader.hideLoader)
  } finally {
    dispatch(actions.loader.hideLoader)
  }
}

const addSignature = signature => ({
  type: types.ADD_SIGNATURE,
  payload: signature
})

const updateSignature = signature => ({
  type: types.UPDATE_SIGNATURE,
  payload: signature
})

const cleanSignatures = () => ({
  type: types.CLEAN_SIGNATURE
})

const deleteSignature = ({id, personId}) => async (dispatch, getState) => {
  try {
    let dataLoad
    const currentState = getState().signature.signaturesByPerson[personId]
    // validate if it is a uuid(not saved to db yet)
    if(validate(id)) {
      dataLoad = currentState.filter(sign => id !== sign.id)
    } else {
      dataLoad = await signature.delete({id, personId})
      const localData = currentState.filter(item => validate(item.id))
      dataLoad.push(...localData)
    }

    if(dataLoad.error) {
      return
    }

    dispatch(signatureSuccess(dataLoad))
  } catch(err) {
    dispatch(signatureFailure())
    dispatch(actions.loader.hideLoader)
  } finally {
    dispatch(actions.loader.hideLoader)
  }
}

const signatureActions = {
	signatureSuccess,
	signatureFailure,
  loadSignatureByPerson,
  addSignature,
  updateSignature,
  cleanSignatures,
  deleteSignature,
}

export default signatureActions
