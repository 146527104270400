import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import actions from '../../../../store/actions'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone'
import AlertDialog from '../../../molecules/ConfirmDialog/ConfirmDialog'

const DeleteButton = ({ id }) => {
	const dispatch = useDispatch()
	return (
		<AlertDialog
			dialogTitle={'Deletar Pessoa!'}
			dialogText={'Tem certeza que deseja deletar o registro'}
			agreeText={'Confirma'}
			disagreeText={'Cancelar'}
			onAgreement={() => dispatch(actions.people.deletePerson(id))}
		>
			<DeleteForeverTwoToneIcon style={{ cursor: 'pointer' }}/>
		</AlertDialog>
	)
}

export const DataTableColumns = [
	{
		name: 'Nome',
		selector: 'name',
		sortable: true,
		center: true,
	},
	{
		name: 'CPF',
		selector: 'document',
		sortable: true,
		center: true,
	},
	{
		name: 'Situação',
		selector: 'status',
		sortable: true,
		center: true,
	},
	{
		name: 'Data de cadastro',
		selector: 'created',
		format: row => moment(row.created).format('DD/MM/YYYY'),
		sortable: true,
		center: true,
	},
	{
		cell: row => <DeleteButton id={row.id} />,
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
		center: true,
	},
]
