import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  height: 100px;
  padding: 30px 0;
  justify-content: space-evenly;
  border-bottom: 2px solid #000;
`;

export const PrintWrapper = styled.div`
  display: flex;
  height: 100px;
  padding: 25px 0;
  justify-content: space-evenly;
`;

export const PrintContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  margin: 10px;
  width: 100%;
  justify-content: center;
`;

export const Img = styled.img`
  width: auto;
  height: 140px;
  padding-top: 10px;
`;
