import styled from 'styled-components'

export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 5%;
  right: 5%;
  
  @media screen and (max-width: 800px) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 90vh;
  overflow-y: auto;
`
export const Title = styled.div`
  flex: 1;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding-top: 30px;
`
