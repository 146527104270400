export const inputFields = [
  {id: "name", label:"Nome", type:"text", name:"name", required: true, errorMessage: "Nome é obrigatório."},
  {id: "document", label:"CPF", type:"text", name:"document", required: true, errorMessage: "CPF é obrigatório."},
  {id: "birthday", label:"Data aniversário", type:"date", name:"birthday", required: true, errorMessage: "Aniversário é obrigatório."},
  {id: "record", label:"Número Processo", type:"text", name:"record", required: true, errorMessage: "Número do processo é obrigatório."},
  {id: "address", label:"Endereço", type:"address", name:"address", required: true, errorMessage: "Endereço é obrigatório."},
  {id: "phone1", label:"Telefone 1", type:"tel", name:"phone1", required: true, errorMessage: "Telefone é obrigatório."},
  {id: "mother", label:"Nome Mãe", type:"text", name:"mother", required: false, errorMessage: "Nome da mãe é obrigatório"},
  {id: "landmark", label:"Ponto de referencia", type:"text", name:"landmark", required: false, errorMessage: ""},
  {id: "phone2", label:"Telefone 2", type:"tel", name:"phone2", required: false, errorMessage: ""},
  {id: "phone3", label:"Telefone 3", type:"tel", name:"phone3", required: false, errorMessage: ""},
  {id: "startdate", label:"Data Inicial", type:"date", name:"startdate", required: true, errorMessage: "Data inicial é obrigatório"},
  {id: "infopen", label:"INFOPEN", type:"text", name:"infopen", required: false, errorMessage: ""},
  {id: "card", label:"Carteirinha", type:"text", name:"card", required: false, errorMessage: ""},
]

export const formInitialState = formData => {
  return ({
    name: '',
    document: '',
    birthday: '',
    record: '',
    mother: '',
    address: '',
    landmark: '',
    phone1: '',
    phone2: '',
    phone3: '',
    status: '',
    startDate: '',
    infopen: '',
    card: '',
    processtype: '',
    processfrequency: '',
    ...formData,
  })
}
