import types from '../constants'

const openModal = () => ({
	type: types.OPEN_MODAL,
})

const closeModal = () => ({
	type: types.CLOSE_MODAL,
})

const modalActions = {
	openModal,
	closeModal, 
}

export default modalActions
