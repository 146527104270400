import styled from 'styled-components'

export const TextField = styled.input`
  height: 50px;
  width: 270px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`

export const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 52px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`
