import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FilterDataTable } from '../../molecules';
import { Content } from './styled';
import actions from '../../../store/actions';
import _ from 'lodash';
import { DataTableColumns } from './DataTableColumns';

const ActivityFeed = () => {
  const { activities } = useSelector((state) => ({
    activities: state.activity.activities,
  }));

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    try {
      dispatch(actions.activity.fetchActivity());
    } catch (err) {
      const { from } = location.state || { from: { pathname: '/dashboard' } };
      history.push('/', from);
      throw new Error(err);
    }
  }, [dispatch, history, location.state]);


  const filterByEmail = (item) => {
    return item.user_email?.toUpperCase().includes(filterText.toUpperCase());
  };

  const filteredItems =
    _.isArray(activities) && activities.filter((item) => filterByEmail(item));

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeHolder='Filtrar por Email'
        arialLabel='Email para busca'
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Content>
      <DataTable
        columns={DataTableColumns}
        data={filteredItems}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Linhas por página:',
          rangeSeparatorText: 'de',
        }}
        highlightOnHover
        persistTableHead
        noDataComponent={'Nenhuma informação disponível'}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationResetDefaultPage={resetPaginationToggle}
      />
    </Content>
  );
};

export default ActivityFeed;
