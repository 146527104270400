import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container, Header, Content, Footer, Img } from './styled';
import DashboardRoutes from '../../../config/routes';
import AliancaDeMisericordia from '../../../assets/images/aliancademisericordia.png';
import { Copyright } from '../../molecules/Copyright';
import Sidebar from '../../molecules/Sidebar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#f6f6f6',
  },
  menuButton: {
    color: 'black',
  },
}));

const App = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const switchRoutes = (
    <Switch>
      {DashboardRoutes.map((prop, key) => {
        if (prop.layout === '/dashboard') {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from='/dashboard' to='/dashboard/home' />
    </Switch>
  );

  return (
    <Container>
      <AppBar position='relative' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer(true)}
            edge='start'
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Header>
            <Img src={AliancaDeMisericordia} alt='Aliança de Misericordia' />
          </Header>
        </Toolbar>
      </AppBar>
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Content>{switchRoutes}</Content>
      <Footer>
        <Copyright />
      </Footer>
    </Container>
  );
};

export default App;
