import React from 'react'
import { useDispatch } from 'react-redux'
import actions from '../../../../store/actions'
import DeleteForeverTwoToneIcon from '@material-ui/icons/DeleteForeverTwoTone'
import AlertDialog from '../../../molecules/ConfirmDialog/ConfirmDialog'

const DeleteButton = ({ id }) => {
	const dispatch = useDispatch()
	return (
		<AlertDialog
			dialogTitle={'Deletar Colaborador!'}
			dialogText={'Tem certeza que deseja deletar o registro'}
			agreeText={'Confirmar'}
			disagreeText={'Cancelar'}
			onAgreement={() => dispatch(actions.account.deleteUser(id))}
		>
			<DeleteForeverTwoToneIcon style={{ cursor: 'pointer' }}/>
		</AlertDialog>
	)
}

export const DataTableColumns = [
	{
		name: 'Nome',
		selector: 'first_name',
		sortable: true,
		center: true,
	},
	{
		name: 'Sobrenome',
		selector: 'last_name',
		sortable: true,
		center: true,
	},
	{
		name: 'Email',
		selector: 'email',
		sortable: true,
		center: true,
	},
	{
		name: 'Role',
		selector: 'roles',
		sortable: true,
		center: true,
	},
	{
		cell: row => <DeleteButton id={row.id} />,
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
		center: true,
	},
]
