import moment from 'moment'

const mapActivityAction = {
	'UPDATE_PERSON': 'Criou pessoa',
	'CREATE_PERSON': 'Atualizou pessoa',
	'DELETE_PERSON': 'Deletou pessoa',
}

export const DataTableColumns = [
	{
		name: 'Atividade',
		selector: 'action',
		format: row => mapActivityAction[row.action],
		sortable: true,
		center: true,
	},
	{
		name: 'Email Ator',
		selector: 'user_email',
		sortable: true,
		center: true,
	},
	{
		name: 'Nome Pessoa',
		selector: 'name',
		sortable: true,
		center: true,
	},
	{
		name: 'Documento Pessoa',
		selector: 'document',
		sortable: true,
		center: true,
	},
	{
		name: 'Data e Hora',
		selector: 'action_time',
		format: row => moment(row.created).format('DD/MM/YYYY, HH:mm:ss'),
		sortable: true,
		center: true,
	},
]
