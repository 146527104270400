import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: rgb(187 195 204 / 31%);
`

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: white;
  padding: 30px;
  border-radius: 9px;
  box-shadow:
  0 0.4px 0.4px -14px rgba(0, 0, 0, 0.048),
  0 1px 0.9px -14px rgba(0, 0, 0, 0.069),
  0 1.9px 1.8px -14px rgba(0, 0, 0, 0.085),
  0 3.4px 3.1px -14px rgba(0, 0, 0, 0.101),
  0 6.3px 5.8px -14px rgba(0, 0, 0, 0.122),
  0 15px 14px -14px rgba(0, 0, 0, 0.17);
`

export const Img = styled.img`
  width: 200px;
  padding-bottom  : 10px;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`