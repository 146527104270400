import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel';

const Selector = ({
 items = [],
 label,
 name,
 onChange,
 selectedOption,
 placeholder = '',
 validationMessage = '',
}) => {
  return (
  <FormControl>
    <InputLabel>{label}</InputLabel>
    <Select
      name={name}
      value={selectedOption}
      onChange={onChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {placeholder && 
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      }
      {items.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
    </Select>
    {validationMessage && <FormHelperText>{validationMessage}</FormHelperText>}
  </FormControl>
)}

export default Selector