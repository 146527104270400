import React from 'react'
import Modal from '@material-ui/core/Modal'
import { Content, Header, Title, Body } from './styled'
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone'

const ModalUI = ({
  open,
  onClose,
	body,
	title
}) => (
	<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={open}
			onClose={onClose}
		>
		<Content>
			<Header>
				<Title> {title} </Title>
				<CancelTwoToneIcon onClick={onClose} />
			</Header>
			<Body>
				{body}
			</Body>
		</Content>
	</Modal>
)

export default ModalUI