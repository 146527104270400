const constants = {
  SET_USER: 'SET_USER',
  REMOVE_USER: 'REMOVE_USER',
  SUCCESS_USER: 'SUCCESS_USER',
  FAILURE_USER: 'FAILURE_USER',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  LOAD_PEOPLE: 'LOAD_PEOPLE',
  SUCCESS_PEOPLE: 'SUCCESS_PEOPLE',
  FAILURE_PEOPLE: 'FAILURE_PEOPLE',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SIGNATURE_SUCCESS: 'SIGNATURE_SUCCESS',
  SIGNATURE_FAILURE: 'SIGNATURE_FAILURE',
  UPDATE_SIGNATURE: 'UPDATE_SIGNATURE',
  ADD_SIGNATURE: 'ADD_SIGNATURE',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  HIDE_MESSAGE: 'HIDE_MESSAGE',
  CLEAN_SIGNATURE: 'CLEAN_SIGNATURE',
  REPORT_SUCCESS: 'REPORT_SUCCESS',
  REPORT_FAILURE: 'REPORT_FAILURE',
  REPORT_LOADING: 'REPORT_LOADING',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAIL: 'FETCH_USER_FAIL',
  FETCH_ACTIVITY_FEED: 'FETCH_ACTIVITY_FEED',
  ACTIVITY_FEED_SUCCESS: 'ACTIVITY_FEED_SUCCESS',
  ACTIVITY_FEED_FAIL: 'ACTIVITY_FEED_FAIL',
  ACTIVITY_FEED_LOADING: 'ACTIVITY_FEED_LOADING',
};

export default constants;
