import * as Api from './api';
import Endpoints from '../enums/endpoints';

const account = {
  list: async () => {
    try {
      const url = Endpoints.GET_USERS;
      const request = await Api.get(url);
      return request.response;
    } catch (err) {
      return { error: err };
    }
  },
  get: async (id) => {
    try {
      const url = Endpoints.GET_USER(id);
      const request = await Api.get(url);
      return request.response;
    } catch (err) {
      return { error: err };
    }
  },
  post: async (payload) => {
    try {
      const url = Endpoints.POST_USER;
      const { data } = await Api.post(url, payload);
      return data;
    } catch (err) {
      return { error: err };
    }
  },
  update: async (payload) => {
    try {
      const url = Endpoints.UPDATE_USER;
      const { data } = await Api.put(url, payload);
      return data;
    } catch (err) {
      return { error: err };
    }
  },
  delete: async id => {
    try {
      const url = Endpoints.DELETE_USER(id)
      const { data } = await Api.remove(url)
      return data
    } catch(err) {
      return ({ error: err })
    }
  }
};

export default account;
