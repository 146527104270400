switch (process.env.NODE_ENV) {
    case 'develop':
        module.exports = require('./configureStore.dev');
        break;
    case 'production':
        module.exports = require('./configureStore.prod');
        break;
    default:
        module.exports = require('./configureStore.prod');
        break;
}
