import * as Api from './api'
import Endpoints from '../enums/endpoints'

const signature = {
  get: async id => {
    try {
      const url = Endpoints.GET_SIGNATURE_BY_PERSON(id)
      const { response } = await Api.get(url)
      return response
    } catch(err) {
      return ({ error: err })
    }
  },
  post: async data => {
    try {
      const url = Endpoints.POST_SIGNATURE
      const { response } = await Api.post(url, data)
      return response
    } catch(err) {
      return ({ error: err })
    }
  },
  update: async data => {
    try {
      const url = Endpoints.UPDATE_SIGNATURE
      const { message } = await Api.put(url, data)
      return message
    } catch(err) {
      return ({ error: err })
    }
  },
  delete: async data => {
    try {
      const url = Endpoints.DELETE_SIGNATURE_BY_PERSON(data)
      const { data: result } = await Api.remove(url)
      return result
    } catch(err) {
      return ({ error: err })
    }
  }
}

export default signature