import React from 'react'
import { TextField, ClearButton, Row } from './styled'

const FilterDataTable = ({ filterText, onFilter, onClear, placeHolder, arialLabel }) => (
  <Row>
    <TextField id="search" type="text" placeholder={placeHolder} aria-label={arialLabel} value={filterText} onChange={onFilter} />
    <ClearButton type="button" onClick={onClear}>X</ClearButton>
  </Row>
)

export default FilterDataTable