import React from 'react';
import {
  Route,
  Redirect,
} from "react-router-dom";

import authenticate from '../../../services/authenticate';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticate.isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

export default PrivateRoute;