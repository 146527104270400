import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Selector } from '../../atoms';
import { Button, Typography } from '@material-ui/core';
import moment from 'moment';
import actions from '../../../store/actions';
import {
  Wrapper,
  ButtonWrapper,
  PrintWrapper,
  PrintContentWrapper,
} from './styled';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ReportContent from './ReportContent';
import Constants from '../Person/components/ModalContent/constants';
import { aliancaDeMisericordia } from './aliancaDeMisericordia';

export const Report = () => {
  const [reportName, setReportName] = useState('');
  const [processType, setProcessType] = useState('');
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'months').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const dispatch = useDispatch();

  const { loadingReport, report } = useSelector((state) => ({
    report: state.report.report,
    reportLoading: state.report.reportLoading,
  }));

  const handleStartDatePicker = (event) => {
    const formatedPickedDate = moment(event.target.value).format('YYYY-MM-DD');
    setStartDate(formatedPickedDate);
  };

  const handleEndDatePicker = (event) => {
    const formatedPickedDate = moment(event.target.value).format('YYYY-MM-DD');
    setEndDate(formatedPickedDate);
  };

  const handleChange = (event) => {
    setProcessType(event.target.value);
  };

  return (
    <Wrapper>
      <Input
        key='start-date'
        id='start-date'
        label='Data Inicial'
        type='date'
        name='start-date'
        value={startDate}
        onChange={handleStartDatePicker}
        format='dd-MM-yyyy'
      />
      <Input
        key='end-date'
        id='end-date'
        label='Data Limite'
        type='date'
        name='end-date'
        value={endDate}
        onChange={handleEndDatePicker}
        format='dd-MM-yyyy'
      />
      <Selector
        id='processtype'
        label='Tipo de Processo'
        name='processtype'
        selectedOption={processType}
        items={Constants.processTypes}
        onChange={handleChange}
      />
      <ButtonWrapper>
        <Button
          key='button-fetch-report'
          type={'button'}
          variant={`${reportName === 'noSign' ? 'contained' : 'outlined'}`}
          color='primary'
          onClick={() => {
            dispatch(
              actions.report.loadReportNoSignature({
                startDate,
                endDate,
                processType,
              })
            );
            setReportName('noSign');
          }}
        >
          Gerar relatório - não consta assinatura
        </Button>
        <Button
          key='button-fetch-report2'
          type={'button'}
          variant={`${reportName === 'noInfo' ? 'contained' : 'outlined'}`}
          color='primary'
          onClick={() => {
            dispatch(
              actions.report.loadReportNoShow({
                startDate,
                endDate,
                processType,
              })
            );
            setReportName('noInfo');
          }}
        >
          Gerar relatório - não compareceu
        </Button>
        <Button
          key='button-fetch-report3'
          type={'button'}
          variant={`${reportName === 'byProcess' ? 'contained' : 'outlined'}`}
          color='primary'
          onClick={() => {
            dispatch(actions.report.loadReportByProcess({ processType }));
            setReportName('byProcess');
          }}
        >
          Gerar relatório - por tipo de Processos
        </Button>
      </ButtonWrapper>
      <PrintWrapper>
        <Button
          key='button-fetch-report2'
          type={'button'}
          variant={`${loadingReport ? 'outlined' : 'contained'}`}
          color='secondary'
          disabled={loadingReport || report?.length === 0}
          onClick={() => {
            const doc = new jsPDF({
              orientation: reportName === 'noSign' ? 'l' : 'p'
            });
            const table = document.getElementById('tableContainer');
            const header = `
            
            Rua Lázara Silva, 211 – Belvedere II.
            Telefone: (37) 99779-1313.
            
            Data Inicio:${moment(startDate).format(
              'DD/MM/YYYY'
            )} - Data Fim: ${moment(endDate).format('DD/MM/YYYY')}
            `;
            var res = doc.autoTableHtmlToJson(table);

            doc.autoTable(res.columns, res.data, {
              margin: { top: 80 },
              styles: {
                cellPadding: 0.5,
                fontSize: 12,
              },
              headerStyles: {
                fontSize: 12
              },
              bodyStyles: {
                margin: 40,
                fontSize: 10,
                lineWidth: 0.2,
                lineColor: [0, 0, 0],
              },
              theme: 'striped',
              showHead: 'everyPage',
              didDrawPage: function (information) {
                doc.addImage(aliancaDeMisericordia, 'JPEG', doc.internal.pageSize.getWidth() - 120, 5, 40, 40);
                // Header
                doc.setFontSize(12);
                doc.setTextColor(40);
                doc.text(header, doc.internal.pageSize.getWidth() / 2, 50, null, null, 'center');

                // Footer
                const footer = 'Página ' + doc.internal.getNumberOfPages();

                doc.setFontSize(10);

                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                const pageSize = doc.internal.pageSize;
                const pageHeight = pageSize.height
                  ? pageSize.height
                  : pageSize.getHeight();
                doc.text(footer, information.settings.margin.left, pageHeight - 10);
              },
            });
            doc.save(`relatorio-${reportName}-${startDate}-${endDate}.pdf`);
          }}
        >
          Imprimir PDF
        </Button>
      </PrintWrapper>
      <PrintContentWrapper>
        <Typography variant='h5'>Pré Visualização de Relatório</Typography>
        <ReportContent
          dataSet={{ report: report || [] }}
          reportName={reportName}
        />
      </PrintContentWrapper>
    </Wrapper>
  );
};
