import React from 'react'
import CompanhiaSites from '../../../assets/images/companhiaSites.png'
import { Typography } from '@material-ui/core'
import { Anchor, Img, CopyrightWrrapper } from './styled'

export const Copyright = () => {
  return (
    <CopyrightWrrapper>
      <Typography>Desenvolvido e Mantido por</Typography>
      <Anchor url="https://companhiasites.com.br/">
        <Img src={CompanhiaSites} alt="Companhia Sites"/>
      </Anchor>
    </CopyrightWrrapper>
  )
}