import types from '../constants';
import actions from '.';
import account from '../../services/account';

const setUser = (user) => ({
  type: types.SET_USER,
  payload: user,
});

const removeUser = () => ({
  type: types.REMOVE_USER,
});

const successUser = (data) => ({
  type: types.SUCCESS_USER,
  payload: data,
});

const fetchUserSuccess = (data) => ({
  type: types.FETCH_USER_SUCCESS,
  payload: data,
});

const fetchUserFail = (data) => ({
  type: types.FETCH_USER_FAIL,
});

const failureUser = () => ({
  type: types.FAILURE_USER,
});

const loadUsers = () => async (dispatch) => {
  dispatch(actions.loader.showLoader);
  try {
    const dataLoad = await account.list();
    dispatch(successUser(dataLoad || []));
  } catch (err) {
    dispatch(failureUser());
    dispatch(actions.loader.hideLoader);
  } finally {
    dispatch(actions.loader.hideLoader);
  }
};

const loadUser =
  ({ id }) =>
  async (dispatch) => {
    dispatch(actions.loader.showLoader);
    try {
      const dataLoad = await account.get(id);
      dispatch(fetchUserSuccess(dataLoad || null));
    } catch (err) {
      dispatch(fetchUserFail());
      dispatch(actions.loader.hideLoader);
    } finally {
      dispatch(actions.loader.hideLoader);
    }
  };

const createUser = (payload) => async (dispatch) => {
  dispatch(actions.loader.showLoader);
  try {
    const data = await account.post(payload);
    dispatch(successUser(data || []));
    return { success: true };
  } catch (err) {
    dispatch(failureUser());
    dispatch(actions.loader.hideLoader);
    return { success: false, error: err };
  }
};

const updateUser = (data) => async (dispatch) => {
  dispatch(actions.loader.showLoader);
  try {
    const userResult = await account.update(data);
    dispatch(successUser(userResult || []));
    return { success: true };
  } catch (err) {
    dispatch(failureUser());
    dispatch(actions.loader.hideLoader);
    return { success: false, error: err };
  } finally {
    dispatch(actions.loader.hideLoader);
  }
};

const deleteUser = (id) => async dispatch => {
  dispatch(actions.loader.showLoader)
  try {
    const data = await account.delete(id)
    dispatch(successUser(data || []))
    return { success: true}
  } catch(err) {
    dispatch(failureUser())
    dispatch(actions.loader.hideLoader)
    return { success: false, error: err}
  } finally {
    dispatch(actions.loader.hideLoader)
  }
}

const accountActions = {
  setUser,
  removeUser,
  loadUsers,
  loadUser,
  createUser,
  updateUser,
  deleteUser,
};

export default accountActions;
