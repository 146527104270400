import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../store/actions';
import authenticate from '../../../services/authenticate';
import { Message } from '../../molecules';
import AliancaDeMisericordia from '../../../assets/images/aliancademisericordia.png';

import { Overlay, Wrapper, Content, Form, Img, ImageWrapper } from './styled';
import { Copyright } from '../../molecules/Copyright';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { show, message } = useSelector((state) => ({
    show: state.loader.show,
    message: state.message.open,
  }));

  const validateForm = () => {
    return email.length >= 6 && password.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(actions.loader.showLoader(true));
    try {
      const auth = await authenticate.login(email, password);
      const { token, user, data } = auth;

      if (token && user) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', user);
        localStorage.setItem('roles', data.roles);
        dispatch(
          actions.account.setUser({ email: user, token: token, ...data })
        );
        const { from } = location.state || { from: { pathname: '/dashboard' } };
        history.replace(from);
      }
    } catch (err) {
      dispatch(actions.message.showMessage());
    } finally {
      dispatch(actions.loader.hideLoader());
    }
  };

  const onCloseMessage = () => {
    dispatch(actions.message.hideMessage());
  };

  return (
    <Overlay>
      <Wrapper>
        <ImageWrapper>
          <Img src={AliancaDeMisericordia} alt='Aliança de Misericordia' />
        </ImageWrapper>
        <Content>
          {show ? (
            <CircularProgress />
          ) : (
            <Form onSubmit={handleSubmit}>
              <TextField
                id={'user-input'}
                required
                variant={'filled'}
                type={'text'}
                name={'user'}
                placeholder={'Email'}
                inputProps={{ 'aria-label': 'hidden label' }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                id={'password-input'}
                required
                variant={'filled'}
                type={'password'}
                name={'password'}
                placeholder={'Password'}
                onChange={(e) => setPassword(e.target.value)}
                inputProps={{ 'aria-label': 'hidden label' }}
              />
              <Button
                type={'submit'}
                variant={'contained'}
                disabled={!validateForm()}
              >
                Login
              </Button>
            </Form>
          )}
        </Content>
        {message && (
          <Message
            open={message}
            severity='error'
            onClose={onCloseMessage}
            message={'Email ou Senha incorretos.'}
          />
        )}
      </Wrapper>
      <Copyright />
    </Overlay>
  );
};

export default Login;
