import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Button } from '@material-ui/core';
import PersonAddTwoToneIcon from '@material-ui/icons/PersonAddTwoTone';
import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';
import { Modal, FilterDataTable } from '../../molecules';
import { Content, Row } from './styled';
import { formInitialState } from './helpers/InputFields';
import { DataTableColumns } from './helpers/DataTableColumns';
import actions from '../../../store/actions';
import { ModalContent } from './components';
import Report from '../Report';
import _ from 'lodash';

const Person = () => {
  const { all, modalStatus, signatures, message } = useSelector((state) => ({
    all: state.people.all,
    modalStatus: state.modal.open,
    signatures: state.signature.signaturesByPerson,
    message: state.message.open,
  }));

  const [mode, setMode] = useState('');
  const [reportModal, setOpenReportModal] = useState(false);
  const [state, setState] = useState(formInitialState());
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    try {
      dispatch(actions.people.loadPeople());
    } catch (err) {
      const { from } = location.state || { from: { pathname: '/dashboard' } };
      history.push('/', from);
      throw new Error(err);
    }
  }, [dispatch, history, location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event, mode) => {
    event.preventDefault();
    // TO DO : improve this logic to better reading. Get required from the input configuration and find out what is required or not
    if (
      state.name === '' ||
      state.birthday === '' ||
      state.startdate === '' ||
      state.address === '' ||
      state.record === '' ||
      state.document === '' ||
      state.phone1 === ''
    ) {
      dispatch(actions.message.showMessage());
      return;
    }
    if (mode === 'create') {
      dispatch(actions.people.createPerson(state, signatures));
    } else if (mode === 'edit') {
      dispatch(actions.people.updatePerson(state, signatures));
    }
    dispatch(actions.modal.closeModal());
  };

  const handleModalClose = () => {
    dispatch(actions.modal.closeModal());
  };

  const handleModalOpen = (formData) => {
    setMode(formData ? 'edit' : 'create');
    setState(formInitialState(formData));
    dispatch(actions.modal.openModal());
  };

  const handleReportModal = () => {
    setOpenReportModal(!reportModal);
  };

  const filterByNameOrDocument = (item) => {
    return (
      item.document?.toUpperCase().includes(filterText.toUpperCase()) ||
      item.name?.toUpperCase().includes(filterText.toUpperCase())
    );
  };

  const filteredItems =
    _.isArray(all) && all.filter((item) => filterByNameOrDocument(item));

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterDataTable
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        placeHolder='Filtrar por CPF ou Nome'
        arialLabel='CPF ou Nome para busca'
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <Modal
        open={modalStatus}
        onClose={handleModalClose}
        title={`${mode === 'create' ? 'Criar' : 'Editar'} nova Pessoa`}
        body={
          <ModalContent
            handleChange={handleChange}
            state={state}
            signatures={signatures}
            message={message}
            handleModalClose={handleModalClose}
            handleSubmit={handleSubmit}
            mode={mode}
            hideMessage={() => dispatch(actions.message.hideMessage())}
          />
        }
      />
      <Modal
        open={reportModal}
        onClose={handleReportModal}
        body={<Report />}
        title='Relatórios de pessoas'
      />
      <Content>
        <Row>
          <Button
            key='button-submit'
            type={'submit'}
            variant={'contained'}
            onClick={() => handleModalOpen(null)}
          >
            <PersonAddTwoToneIcon />
            Criar Nova Pessoa
          </Button>
          <Button
            key='button-noSignatureReport'
            type={'submit'}
            variant={'contained'}
            onClick={() => handleReportModal()}
          >
            <AssignmentTwoToneIcon />
            Relatórios
          </Button>
        </Row>
        <DataTable
          columns={DataTableColumns}
          data={filteredItems}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Linhas por página:',
            rangeSeparatorText: 'de',
          }}
          highlightOnHover
          persistTableHead
          noDataComponent={'Nenhuma informação disponível'}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          paginationResetDefaultPage={resetPaginationToggle}
          onRowClicked={(row) => handleModalOpen(row)}
        />
      </Content>
    </>
  );
};

export default Person;
