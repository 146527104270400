import constants from '../constants';

const initialState = {
	all: []
};

const { SUCCESS_PEOPLE, FAILURE_PEOPLE } = constants

const personReducer = (state = initialState, { payload, type }) => {
	switch (type) {
		case SUCCESS_PEOPLE:
			return { ...state, all: payload }
		case FAILURE_PEOPLE:
			return { ...initialState }
		default:
			return state;
	}
}

export default personReducer