import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const Input = ({ label, type, value, onChange, name, required = false, validationMessage = '', error = false, disabled = false }) => {
  return (
    <FormControl>
      <TextField
        id="outlined-number"
        label={label}
        value={value}
        type={type}
        name={name} 
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange}
        margin="normal"
        required={required}
        disabled={disabled}
      />
      {error && <FormHelperText>{validationMessage}</FormHelperText>}
    </FormControl>
)}

export default Input