import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { sortNameAlphabetic } from '../../../utils/sort';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey,
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  body: {
    fontSize: 14,
    borderBottom: '1px solid',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const bodyForNoSign = (dataSet) =>
  dataSet.map((row, id) => (
    <StyledTableRow key={id}>
      <StyledTableCell>{id + 1}</StyledTableCell>
      <StyledTableCell component='th' scope='row'>
        {row.name}
      </StyledTableCell>
      <StyledTableCell>{row.status}</StyledTableCell>
      <StyledTableCell>{row.infopen}</StyledTableCell>
      <StyledTableCell>{row.record}</StyledTableCell>
      <StyledTableCell>{row.phone1}</StyledTableCell>
      <StyledTableCell>{row.address}</StyledTableCell>
      <StyledTableCell>{row.reason}</StyledTableCell>
    </StyledTableRow>
  ));

const bodyForNoInfo = (dataSet) =>
  dataSet.map((row, id) => (
    <StyledTableRow key={id}>
      <StyledTableCell>{id + 1}</StyledTableCell>
      <StyledTableCell component='th' scope='row'>
        {row.name}
      </StyledTableCell>
      <StyledTableCell>{row.status}</StyledTableCell>
      <StyledTableCell>{row.processtype}</StyledTableCell>
      <StyledTableCell>{row.phone1}</StyledTableCell>
      <StyledTableCell>{row.address}</StyledTableCell>
    </StyledTableRow>
  ));

const bodyForByProcess = (dataSet) =>
  dataSet.map((row, id) => (
    <StyledTableRow key={id}>
      <StyledTableCell>{id + 1}</StyledTableCell>
      <StyledTableCell>{row.name}</StyledTableCell>
      <StyledTableCell>{row.status}</StyledTableCell>
      <StyledTableCell component='th' scope='row'>
        {row.address}
      </StyledTableCell>
      <StyledTableCell>{row.phone1}</StyledTableCell>
      <StyledTableCell>{row.processfrequency}</StyledTableCell>
    </StyledTableRow>
  ));

const renderContent = (reportName, dataSet) => {
  switch (reportName) {
    case 'noSign':
      return bodyForNoSign(dataSet);
    case 'noInfo':
      return bodyForNoInfo(dataSet);
    case 'byProcess':
      return bodyForByProcess(dataSet);
    default:
      break;
  }
};

const headerForNoSign = () => (
  <TableRow>
    <StyledTableCell>#</StyledTableCell>
    <StyledTableCell>Nome da Pessoa</StyledTableCell>
    <StyledTableCell>Situação</StyledTableCell>
    <StyledTableCell>INFOPEN</StyledTableCell>
    <StyledTableCell>Número do Processo</StyledTableCell>
    <StyledTableCell>Telefone</StyledTableCell>
    <StyledTableCell>Endereço</StyledTableCell>
    <StyledTableCell>Razão de não Assinar</StyledTableCell>
  </TableRow>
);

const headerForNoInfo = () => (
  <TableRow>
    <StyledTableCell>#</StyledTableCell>
    <StyledTableCell>Nome da Pessoa</StyledTableCell>
    <StyledTableCell>Situação</StyledTableCell>
    <StyledTableCell>Tipo Processo</StyledTableCell>
    <StyledTableCell>Telefone</StyledTableCell>
    <StyledTableCell>Endereço</StyledTableCell>
  </TableRow>
);

const headerForByProcess = () => (
  <TableRow>
    <StyledTableCell>#</StyledTableCell>
    <StyledTableCell>Nome da Pessoa</StyledTableCell>
    <StyledTableCell>Situação</StyledTableCell>
    <StyledTableCell>Endereço</StyledTableCell>
    <StyledTableCell>Telefone</StyledTableCell>
    <StyledTableCell>Frequencia</StyledTableCell>
  </TableRow>
);

const renderHeader = (reportName) => {
  switch (reportName) {
    case 'noSign':
      return headerForNoSign();
    case 'noInfo':
      return headerForNoInfo();
    case 'byProcess':
      return headerForByProcess();
    default:
      break;
  }
};

const ReportContent = ({ dataSet, reportName }) => {
  const classes = useStyles();

  return (
    <Table
      className={classes.table}
      id='tableContainer'
      aria-label='customized table'
    >
      <TableHead>{renderHeader(reportName)}</TableHead>
      <TableBody>
        {renderContent(reportName, sortNameAlphabetic(dataSet.report))}
      </TableBody>
    </Table>
  );
};

export default ReportContent;
