import styled from 'styled-components'

export const FormFooter = styled.div`
display: flex;
justify-content: flex-end;
margin-top: 40px;
padding-bottom: 50px;
`

export const FormSpacer = styled.div`
display: flex;
flex-direction: column;
margin-top: 15px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 40px;
  flex: 1;
  height: 90vh;
`
